@import '~ca-assets/stylesheets/legacy/_vars';
@import '~ca-assets/stylesheets/global/global_config';

.container{
  margin: 0 auto;
  max-width: 800px;
}

.logo{
  position: absolute;
  bottom: 12px;
  right: 15px;
}

.logo a, .logo a:visited {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.logo a:active, .logo a:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
}

.logo img {
  height: 22px;

  display: block; // otherwise browsers rendering in standards mode
                  // add extra space at the bottom
}

body, h1, h2, h3{
  font-family: $ca-default-font-family;
  color: #222;
}

html {
  background: #f4f0ef
}

a {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

a, a:visited {
  color: #e85a21;
  text-decoration: none;
}

a:active, a:hover { color: #ab3e12 }

.card--error {
  max-width: 100%;
  position: relative;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
  padding: 2em;
  max-width: 960px;
  margin: 2em 1em;
}

.error__main { position: relative; }
.error__text { padding-right: 250px; }

.error__image {
  position: absolute;
  right: -40px; bottom:-50px;
}

.error__image img {
  height: 500px;
  width: 300px;

  display: block; // otherwise browsers rendering in standards mode
                  // add extra space at the bottom
}

.title__number {
  font-size: 200px;
  font-weight: $font-weight-bold;
  margin: 0.1em 0 0.1em -5px;
  line-height: 160px;
  line-height: 0.8em;
  letter-spacing: -5px;
}

.title__message {
  position: relative;
  font-weight: $font-weight-bold;
  font-size: 50px;
  margin: 0.5em 0 0.3em 0;
  line-height: 1.1em;
}

.title__subtitle {
  font-weight: $font-weight-bold;
  font-size: 20px;
  margin: 0.25em 0 0.4em 0;
  line-height: 1.4em;
}

.four-zero-zero .title__number { color: #e75b2d }
.four-zero-three .title__number { color: #5386b5 }
.four-zero-four .title__number { color: #f7ad44 }

.five-zero-zero .title__number {
  color: #cb3544;
  margin-left: -10px;
}

@media screen and (max-width: 650px) {
  .title__number { font-size:120px; }
  .title__message{ font-size:40px; }
  .error__text {
    text-align: center;
    padding-right: 0px;
  }
  .error__image {
    display: block;
    position: static;
    text-align: center;
  }
  .error__image img {
    height: auto;
    max-width: 300px;
    width: 100%;
  }
  .card--error { padding-bottom: 3em; }
}
