@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/typography";
// Fonts
$font-sans-serif: 'Inter', 'Helvetica', Arial, sans-serif;
$font-serif: 'Georgia', 'Times New Roman', Helvetica, Arial, sans-serif;
$font-helvetica-neue: 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Font sizes
$font-size-label: $typography-heading-6-font-size;
$font-size-data: $typography-paragraph-body-font-size;
$font-size-small-copy: $typography-paragraph-small-font-size;
$font-size-subheader: 17px;
$font-size-header: 18px;

$font-weight-light: $typography-paragraph-body-font-weight;
$font-weight-regular: $typography-paragraph-body-font-weight;
$font-weight-semibold: 500;
$font-weight-bold: $typography-paragraph-bold-font-weight;

// Palette Colors - https://cultureamp.atlassian.net/wiki/display/MARK/Palette

$orange-dark: #441b0a; // Navigation hightlight
$brown-dark: #393230; // Background

$font-color-favorable: #0c0;
$font-color-neutral: #ff991a;
$font-color-unfavorable: #f50;

// new branding colours 2015
$coral: #f04b5d;
$coral-hover: #e44a5b;
$coral-active: #cc4252;

$azure: #228dbe;
$azure-dark: #277da4;
$azure-updated: #7f9ba7;
$azure-hover: #4b9bbe;

$seedling: #45ad8f;

$gunmetal: #818181;
$platinum: linear-gradient($color-white 0%, #faf8f7 100%);

$blue: #21afe8;
$light-blue: #6dc6dd;

$white-warm: #fff0df; // Offset Background
$white: #fff;

// -- Variables (pasted from report.scss)
$widget-border-color: rgb(230, 230, 230);
$plus-high: #0c0;
$plus-medium: #f80;
$plus-low: #ff1717;

// survey status palette
$mustard: #f8ad36; // Draft
$green: #66c58a; // Active
$purple: #7e7ede; // Completed
$graphite: #858585; // Paused
$zinc: #b3b3b3; // Archived

$danger-red: #cd3241; //Delete survey
$danger-red-pop: #b1202e;

// Aditional Colours in Use
$brown-light: #665a57;
$brown-mid: #49403e;
$brown-dark: #8d8b88;

// TODO: find a saner way of managing our shades of grey ◕︵◕
$grey-dark: #333;
$grey-mid-dark: #666;
$grey-mid: #6e6e6e;
$grey-middle-mid: #aaa;
$grey-mid-lighter: #6e6e6e;
$grey-light-darker: #ddd;
$grey-light: #999;
$grey-light-lighter: #bbb;
$grey-lighter: #ccc;
$grey-faded: #e2dede;
$light-grey-text: #c1c1c1;

// Control hairlines and borders
$grey-subtle: #f2f2f2;
$grey-subtle-lighter: #f9f9f9;

$error-red: #802828;
$slate: #333333;
$slate-80-percent: #5c5c5c;
$slate-85-percent: #515151;
$tan: #f4f0ef;
$tan-light: #f7f6f6;
$tan-alternate: #f8f6f4;
$tan-lighter: #fbfaf9;
$tan-alternate-hover: #eeeae7;
$ca-header-nav-color-red: #f04b5d;

$light-tan: #fbf9f7;
$tan-header-background: #c2bcbb;

// Spacing
$spacing-base: 1.4em;
$spacing-half: $spacing-base/2;
$spacing-quarter: $spacing-base/4;
$spacing-double: $spacing-base*2;
$spacing-quadruple: $spacing-base*4;

// Some standard widths

$width-content: 730px;
$width-content-two-thirds: 2*$width-content/3; //487
$width-content-half: $width-content/2; //365
$width-content-third: $width-content/3; //243
$width-content-quarter: $width-content/4; //182
$width-content-eighth: $width-content/8; //91
$width-content-sixteenth: $width-content/16; //46

$width-wide-page: 1140px;
$width-page: 1040px;
$width-page-two-thirds: 2*$width-page/3; //693
$width-page-half: $width-page/2; //520
$width-page-third: $width-page/3; //347
$width-page-quarter: $width-page/4; //260
$width-page-eighth: $width-page/8; //130
$width-page-sixteenth: $width-page/16; //65

// global sizes
$header-height: 47px;
$logo-height: 80px;
$logo-width: 80px;

//// Padding
$horizontalPadding: 10px;
$verticalPadding: 4px;

// Widths
$screen-width-smartphone: 767px;

// Form style colors
$button-text-disabled-colour: rgba($color-purple-800-rgb, 0.3);
$button-fill-disabled-colour: #eee;

// z-indexes
$layer-base: 0;
$layer-base-over: $layer-base+1;
$layer-over: 6000;

$layer-over-over: $layer-over + 1;

// Social brand colors
$google-text-grey: #737373;
$google-red: #dd4b39;

$focus-color: $light-blue;

// Borders
// $control-border: solid 1px $grey-subtle; Cant use a solix HEX,  need an alpha, because colours beneath arn't coming through - wernah
$control-border: solid 1px rgba($color-gray-600-rgb, 0.07);

// Button borders
$button-border-width: 1px;
$button-border-width-double: 2px;
$button-border-radius: 3px;

// Effectiveness colours
$reviewer-base-color: rgba(255, 255, 255, 0.85);
$manager-background: #e8c781;
$manager-color: $reviewer-base-color;
$co-worker-background: #727294;
$co-worker-color: $reviewer-base-color;
$direct-report-background: #aaa6bf;
$direct-report-color: $reviewer-base-color;
$self-background: #e2dede;
$self-color: #6d6a68;
$self-border: #c1c1c1;

$workflow-states-highlight: #6d6a68;
$workflow-states-solid: #6e6e6e;
$workflow-states-mid: #aaa;
$workflow-states-faint: #cdcac9;
