@import "~@kaizen/design-tokens/sass/color";
@import '~ca-assets/stylesheets/legacy/vars';
@import '~ca-assets/stylesheets/legacy/mixins/murmur_mixins';

/*
Link styling

With explicit overides for for link-like javascript control elements
*/
.link {
  font-family: $font-sans-serif;
  color: $coral;
  text-decoration: none;
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 1em;
  font-weight: inherit;
  text-transform: inherit;
  transition: all 0.3s ease-out;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    color: $coral-hover;
    text-decoration: underline;
  }
  &[disabled] {
    color: rgba($color-purple-800-rgb, 0.3);
  }
  &.-action {
    color: $azure;
    &:hover {
      color: $azure-dark;
    }
  }
}
.link--small {
  font-size: 14px;
}
.link--caps {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: $font-weight-bold;
}

/*
Link style for when the text is on a higher contrast background where the
body text itself is white, and the contrast between the background color
and the orange is too low (or if there is visual color bleed).
*/
.link--on-color {
  color: white;
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
    color: white;
  }
}

/*
variation of on color link with underline on hover to help redability of lists of items
*/
.link--reverse {
  color: white;
  &:hover {
    opacity: 0.7;
    color: white;
    text-decoration: underline;
  }
}

.link--block {
  transition: all 0.2s ease-out;
  .link-block__highlight {
    transition: all 0.2s ease-out;
    opacity: 0.5;
  }
  &:hover {
    cursor: pointer;
    background: $tan-lighter;
    .link-block__highlight {
      opacity: 1;
    }
  }
}
